@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

.splash {
  align-items: center;
  background-color: #2B9595;

  .splash-container {
    max-width: 90vw;
    text-align: center;
    width: 360px;

    a {
      color: white;
    }

    button {
      background: #2B9595;
      border: 1px solid white;
      color: white;
      font-family: Lexend, sans-serif;
      font-size: 1rem;
      font-weight: bold;
      height: 40px;
      padding: 0 1.5rem;
      text-transform: uppercase;

      &:active, &:hover {
        background: white;
        color: #2B9595;
      }
    }

    img {
      margin-bottom: 3rem;
      width: 100%;
    }

    input[type="text"] {
      border: none;
      display: block;
      font-family: Lexend, sans-serif;
      font-size: 22px;
      font-weight: 500;
      height: 40px;
      margin-bottom: 1.5rem;
      text-align: center;
      width: 100%;
    }

    p {
      color: white;
      font-family: Lexend, sans-serif;
      font-size: 22px;
      font-weight: 500;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
  }
}