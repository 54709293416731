.avatar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  video {
    width: 100%;
    opacity: 1;
  }

  img {
    width: 100%;
    opacity: 1;
    max-width: 512px;
  }

  .talk-video {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
  }

  &.recording,
  &.processing {
    &:after {
      content: '';
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 32px;
      height: 32px;
      background-image: var(--scenario-recording);
      background-size: contain;
    }
  }
  &.processing {
    &:after {
      background-image: var(--scenario-processing);
    }
  }

  &.talking {
    .talk-video {
      opacity: 1;
    }
    .idle-video {
      transition: opacity 100ms ease-in;
      transition-delay: 500ms;
      opacity: 0;
    }
  }

  .stream-info {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: white;
    font-size: 1rem;
    max-width: 45%;
    background-color: rgba(255,255,255,0.2);
  }
}
