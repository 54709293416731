.app-modal-overlay {
  align-items: center;
  background-color: rgba(52, 55, 57, 0.75);
  height: 100%;
  inset: 0;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1010;

  &.visible {
    display: flex;
  }
}

.app-modal {
  background-color: #353739;
  border-radius: 1.25rem;
  width: 308px;

  span {
    color: white;
    width: 100%;
    padding: 3rem 2rem;
  }
  > div {
    border-top: 2px #1a1b1c solid;
    width: 100%;
  }

  button {
    color: #52a2dd;
    background: none;
    border: none;
    flex: 1;
    height: 100%;
    padding: 1rem;
    transition: text-shadow 250ms, font-weight 250ms;

    &:hover {
      text-shadow: 0px 0px 3px rgba(255,255,255, 0.25);
      font-weight: 700;
    }
    &:not(:first-child) {
      border-left: 2px #1a1b1c solid;
    }
  }
}
