@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.kb-container {
  display: flex;
  height: 100%;
  overflow-y: auto;

  .kb-upper {
    margin-top: 5vh;
    position: fixed;
    top: 0;
  }

  .kb-lower {
    bottom: 0;
    margin-bottom: 8vh;
    position: fixed;
  }

  .logo-container {
    align-items: flex-end;
    background: var(--assistant-logo-make) top center no-repeat;
    display: flex;
    height: 128px;
    justify-content: center;
    text-align: center;
    width: 100vw;

    .model {
      color: #948A83;
      font-size: 32px;
    }
  }

  .vehicle-container {
    background: var(--assistant-vehicle) 50% / contain no-repeat;
    background-blend-mode: multiply;
    margin-bottom: 3rem;
    max-height: 45vh;

    img {
      max-width: 100%;
      max-height: 100%;
      opacity: 0;
    }
  }

  .intro {
    padding: 0 1rem;
    text-align: center;

    &.fade-in {
      animation: fadeIn 1s;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  .waveform {
    height: 120px;
    max-width: 640px;
    padding: 0 1rem;
    width: 100%;

    &.hidden {
      display: none;
    }

    canvas {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .kb-container {
    .logo-container {
      height: 96px;
      
      .model {
        font-size: 16px;
      }
    }
  }
}