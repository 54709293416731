.landing-container {
  background-color: white;
  height: 100%;
  margin: 0 auto;
  max-width: 700px;
  overflow-y: auto;
  padding: 0 1rem;
  text-align: center;

  .logo-container {
    background: var(--demo-logo) top center no-repeat;
    height: 80px;
    margin: 1rem 0;
  }
  
  > button {
    background: black;
    border: none;
    color: white;
    font-family: Lexend, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    height: 40px;
    padding: 0 1.5rem;
    text-transform: uppercase;

    &:active, &:hover {
      background: #2B9595;
    }
  }

  h3 {
    font-family: Lexend, sans-serif;
    margin-bottom: 2rem;
  }

  .customer-container {
    margin-bottom: 2rem;

    img {
      border: 2px solid black;
      border-radius: 50%;
    }
  }

  .description {
    font-family: Lexend, sans-serif;
    font-size: 18px;
    margin-bottom: 2rem;
  }

  .eval-list {
    ul {
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        margin-bottom: 20px;
        padding: none;

        button {
          background: white;
          border: 1px solid #2B9595;
          border-radius: 2px;
          color: #2B9595;
          display: block;
          font-family: Lexend, sans-serif;
          font-size: 1rem;
          font-weight: bold;
          height: 40px;
          padding: 0;
          width: 350px;

          &:active, &:hover {
            background: #2B9595;
            color: white;
          }
        }
      }
    }
  }

  .menu-btn {
    margin-top: 2rem;

    .app-link {
      position: static;
    }
  }
}

@media screen and (orientation: portrait) {
  .landing-container {
    max-width: 500px;
  }
}

@media (min-width: 992px) and (orientation: landscape) {
  .landing-container {
      flex: 0 0 auto;
      max-width: 44%;
      max-width: calc(100vh - 7rem - 4.3vw - 64px);
  }
}