.App {
  background-color: white;
  height: 100%;
  display:flex;
  justify-content: center;

  &.knowledge-assistant {
    background-image: var(--assistant-bg-landscape);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &.role-play-landing,
  &.role-play,
  &.role-play-evaluation {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-color: #343233;
  }

  &.role-play {
    align-items: center;
  }

  &.role-play-landing,
  &.role-play,
  &.role-play-evaluation,
  &.audio-transcript {
    background-image: var(--scenario-bg-landscape);
  }

  &.knowledge-assistant,
  &.audio-transcript {
    &::after {
      content: 'Tap to enable audio playback';
      position: fixed;
      bottom: 0;
      padding: 0.5rem 1.5rem;
      background-color: rgba(0,0,0,0.25);
      color: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      z-index: 1001;
    }
    &.audio-enabled::after {
      content: none;
    }
  }

  .header {
    background-color: #2B9595;
    margin-bottom: 4.5rem;
    padding: 2rem;

    img {
      cursor: pointer;
      max-width: 240px;
      width: 33vw;
    }
  }
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
  .App {
    &.role-play-landing,
    &.role-play,
    &.role-play-evaluation,
    &.audio-transcript {
      background-size: 100% auto;
    }
  }
}

@media screen and (orientation: portrait) {
  .App {
    &.role-play-landing,
    &.role-play,
    &.role-play-evaluation,
    &.audio-transcript {
      background-image: var(--scenario-bg-portrait);
    }
  }
}

@media screen and (orientation: portrait) {
  .App {
    &.knowledge-assistant {
      background-image: var(--assistant-bg-portrait);
    }
  }
}

@media screen and (max-width: 767px) {
  .App {
    &.knowledge-assistant {
      background-image: var(--assistant-bg-iphone);
    }
  }
}

.app-link {
  background: none;
  border: none;
  color: #888888;
  font-family: Lexend, sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  text-decoration: underline;
  z-index: 1002;

  &:active, &:hover {
    color: #2B9595;
  }
}

@media (min-width: 600px) {
  .not-mobile {
    display: inline;
    top: 33px;
    left: 33px;
  }

  .mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .not-mobile {
    display: none;
  }

  .mobile {
    display: inline;
    top: 10px;
    left: 10px;
  }
}