.menu {
  display: block;
}

.menu-container {
  margin: 0 auto;
  max-width: 700px;
  padding: 0 1rem;
  text-align: center;

  button {
    background: white;
    border: 1px solid #2B9595;
    border-radius: 2px;
    color: #2B9595;
    display: block;
    font-family: Lexend, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    height: 40px;
    margin: 1.5rem auto 0;
    padding: 0;
    width: 312px;

    &:active, &:hover {
      background: #2B9595;
      color: white;
    }
  }

  h2 {
    color: #2B9595;
    font-family: Lexend, sans-serif;
    font-weight: 500;
    margin-bottom: 2.5rem;
  }
}

@media screen and (orientation: portrait) {
  .menu-container {
    max-width: 500px;
  }
}