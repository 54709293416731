.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    flex-direction: column;

    .overlay-box-container {
        position: absolute;
        bottom: 0;
        left: 0;

        .overlay-header {
            background-color: #fff;
            width: 100vw;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #D9D9D9;
            -webkit-box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
            box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
            
            .spacing-div {
                width: 75px;
                margin-left: 20px;
            }
            h4 {
                font-family: Lexend, sans-serif;
                padding-top: 0.5rem;
            }
            .close-btn {
                width: 75px;
                border: none;
                background-color: transparent;
                cursor: pointer;
                font-family: Lexend, sans-serif;
                font-size: 18px;
                font-weight: 500;
                color: #888888;
                padding: 0;
                margin-right: 20px;
    
                .close-icon {
                    display: flex;
                    align-items: center;

                    span {
                        text-decoration: underline;
                    }

                    svg {
                        margin-left: 10px;

                        path {
                            fill: #888888;
                        }
                    }
                }

                &:active, &:hover {
                    color: #2B9595;

                    .close-icon {
                        svg {
                            path {
                                fill: #2B9595;
                            }
                        }
                    }
                }
            }
        }
        
        .overlay-content {
            width: 100vw;
            height: 60vh;
            position: relative;
            background-color: #fff;
            border: 0;
            font-family: Lexend, sans-serif;
            padding: 20px;
            overflow-y: auto;
        }
    }
}