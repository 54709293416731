@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

.guide-container {
  background-color: black;
  display: flex;
  height: 100%;
  background: no-repeat center / cover url("./plating-tool-open.png"); //100 for desktop

  .title-bar {
    background: none;
    border: none;
    color: #FFFFFF;
    font-family: "Hind", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    position: absolute;
    top: 60px;
    left: 0;
    margin-left: -20px;
    text-decoration: none;
    z-index: 1002;
    background-color: #60676a;
    padding: 6px 30px;
    border-radius: 20px;

  }

  .tools {
    position: absolute;
    top: 0; 
    right: 0;
    width: 700px;
    height: 500px;
    background: no-repeat center / 80% url("./tools_needed.png");
  }

    .transcript {
        width: 100%;
        height: 30vh;
        max-height: 33vh;
        background: #1122317f;//rgba(255, 255, 255, 0.8);
        position: absolute;
        bottom: 0;

        .content {
            // border: 1px dotted red;
            width: 100%;
            height: inherit;
            padding: 30px;
            overflow-y: auto;
            font-weight: bold;
            color: #FFFFFF;
            font-family: "Hind", sans-serif;
            font-weight: 500;
            font-style: normal;

            img {
              max-width: 400px;
            }
        }
    }

  .kb-upper {
    margin-top: 5vh;
    position: fixed;
    top: 0;
  }

  .kb-lower {
    bottom: 0;
    // margin-bottom: 8vh;
    position: fixed;
  }

  .logo-container {
    align-items: flex-end;
    background: var(--assistant-logo-make) top center no-repeat;
    display: flex;
    height: 128px;
    justify-content: center;
    text-align: center;
    width: 100vw;

    .model {
      color: #948A83;
      font-size: 32px;
    }
  }

  .vehicle-container {
    background: var(--assistant-vehicle) 50% / contain no-repeat;
    background-blend-mode: multiply;
    margin-bottom: 3rem;
    max-height: 45vh;

    img {
      max-width: 100%;
      max-height: 100%;
      opacity: 0;
    }
  }

  .intro {
    padding: 0 1rem;
    text-align: center;

    &.fade-in {
      animation: fadeIn 1s;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  .waveform {
    height: 120px;
    max-width: 640px;
    padding: 0 1rem;
    width: 100%;

    &.hidden {
      display: none;
    }

    canvas {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
    .guide-container {
        background: no-repeat center/130% url("./plating-tool.png");
    }
}

  
@media screen and (max-width: 767px) {

  .guide-container {
    .logo-container {
      height: 96px;
      
      .model {
        font-size: 16px;
      }
    }
  }
}