.audio-control {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0;

  button {
    height: 48px;
    width: 48px;
    background-size: contain;
    background-color: transparent;
    border: 0;

    &.end {
      background-image: var(--button-end);

      &:active, &:hover {
        background-image: var(--button-end-active);
      }
    }
    &.talk {
      background-image: var(--button-talk-wait);
    }
  }

  &.recording {
    .talk {
      background-image: var(--button-talk-active);
    }
  }
  &.processing {
    .talk {
      background-image: var(--button-talk-wait);
    }
  }
  &.listening {
    .talk {
      background-image: var(--button-talk);
    }
  }
}

.audio-control-guide {
  width: 95%;
  height: 95%;
  // border: 2px dotted orangered;
  z-index: 1000;

  button {
    height: inherit;
    width: inherit;
    background-size: contain;
    background-color: transparent;
    border: 0;
  }
}

.kb-container {
  .audio-control {
    flex-grow: 0 !important;
    margin: 0 !important;
    padding: 0 !important;

    .talk {
      background: none;
      height: 100%;
      inset: 0;
      position: fixed;
      width: 100%;
      z-index: 1000;
    }

    .end {
      display: none;
    }
  }
}
