.summaries-container {
  background-color: white;
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
  max-width: 700px;
  overflow: hidden;
  padding: 0 1rem;
  text-align: center;

  .logo-container {
    background: var(--demo-logo) top center no-repeat;
    height: 80px;
    margin: 1rem 0;
  }

  h3 {
    font-family: Lexend, sans-serif;
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;

    img {
      padding-left: 10px;
    }
  }

  .summaries-scrollable {
    flex: 1;
    margin: 0 auto;
    max-width: 700px;
    overflow-y: auto;
    padding: 1.5rem 1rem;
    width: 100%;
    //white-space: pre-wrap;

    h3 {
      font-size: 2rem;
      font-weight: 700;
      margin: 0 auto 1rem;
      max-width: 700px;
      width: 100%;
    }

    button {
      background: white;
      border: 1px solid #2B9595;
      border-radius: 2px;
      color: #2B9595;
      display: block;
      font-family: Lexend, sans-serif;
      font-size: 1rem;
      font-weight: bold;
      height: 40px;
      margin: 0 auto;
      padding: 0 1rem;
      width: 312px;
  
      &:active, &:hover {
        background: #2B9595;
        color: white;
      }

      &:not(:first-of-type) {
        margin-top: 1rem;
      }
    }

    .wmde-markdown {
      background-color: #FFF;
      color: #000;
      font-family: Lexend, sans-serif;
      text-align: left;

      p > strong {
        color: #4898D3;
        font-weight: 700;
        font-size: 1.1rem;
      }
    }
  }

  .refresh {
    align-self: flex-end;
    background-color: transparent;
    background-image: var(--button-refresh);
    border: none;
    display: block;
    height: 40px;
    margin: 1rem;
    transition: all 0.25s linear;
    width: 40px;

    &.disabled {
      filter: grayscale(1);
      opacity: 0.25;
    }
  }
}

@media screen and (orientation: portrait) {
  .summaries-container {
    h3 {
      max-width: 600px;
    }

    .summaries-scrollable {
      max-width: 600px;
    }
  }
}