.evaluation-container {
  background-color: white;
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
  max-width: 700px;
  overflow: hidden;
  padding: 0 1rem;
  text-align: center;

  .logo-container {
    background: var(--demo-logo) top center no-repeat;
    height: 80px;
    margin: 1rem 0;
  }

  h3 {
    font-family: Lexend, sans-serif;
    margin-bottom: 2rem;
  }

  .evaluation-scrollable {
    flex: 1;
    font-family: Lexend, sans-serif;
    margin: 0 auto;
    max-width: 700px;
    overflow-y: auto;
    padding: 1.5rem 1rem;
    text-align: left;
    width: 100%;
    //white-space: pre-wrap;

    h3 {
      font-family: Lexend, sans-serif;
      font-size: 2rem;
      font-weight: 700;
      margin: 0 auto 1rem;
      max-width: 700px;
      width: 100%;
    }
  
    h6 {
      color: #4898D3;
      font-family: Lexend, sans-serif;
      font-size: 1.1rem;
      font-weight: 700;
    }

    .evalDate {
      font-weight: 700;
      margin-bottom: 30px;
    }

    // markdown override
    .wmde-markdown {
      background-color: #FFF;
      color: #000;
      font-family: Lexend, sans-serif;

      p > strong {
        color: #4898D3;
        font-weight: 700;
        font-size: 1.1rem;
      }

    }
  }
}

@media screen and (orientation: portrait) {
  .evaluation-container {
    h3 {
      max-width: 600px;
    }

    .evaluation-scrollable {
      max-width: 600px;
    }
  }
}